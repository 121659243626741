<template>
    <div class="container-form">
        <form action="">
            <div class="container-produto">
                <div class="label label-produto">
                    <span class="produto"> Produto </span>
                </div>
                <div class="produto-img">
                    <div class="open-img" @click="showProduto = true">
                        <img src="../../assets/img/fullscreen.svg" alt="abrir imagem" />
                    </div>
                    <img
                        id="produtoImg"
                        :src="
                            'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' +
                            produto.codigo_de_barras +
                            '.webp'
                        "
                        @error="replaceByDefault"
                        alt="produto 1"
                    />
                </div>
            </div>
            <div class="container-inputs">
                <div class="containers">
                    <div class="label">
                        <span class="descricao"> Descrição </span>
                    </div>
                    <div class="input input-codigo">
                        <input type="text" autocorrect="off" v-model="descricao" disabled />
                    </div>
                </div>
                <div class="containers">
                    <div class="label label-codigo">
                        <span class="codigo"> Código de barras </span>
                    </div>
                    <div class="input input-codigo">
                        <input type="number" autocorrect="off" v-model="codigo" disabled />
                    </div>
                </div>
                <div class="containers">
                    <div class="label">
                        <span class="codigo"> Loja </span>
                    </div>
                    <div class="input">
                        <input type="number" autocorrect="off" v-model="loja" disabled />
                    </div>
                </div>
                <div class="containers">
                    <div class="label">
                        <span class="codigo"> Quantidade </span>
                    </div>
                    <div class="input">
                        <input type="number" autocorrect="off" v-model="quantidade" disabled />
                    </div>
                </div>
                <div class="containers">
                    <div class="label">
                        <span class="codigo"> Validade </span>
                    </div>
                    <div class="input">
                        <input type="date" autocorrect="off" v-model="validade" disabled />
                    </div>
                </div>
                <div class="containers">
                    <div class="label">
                        <span class="codigo"> Preço Atual </span>
                    </div>
                    <div class="input">
                        <input type="number" autocorrect="off" v-model="precoAtual" disabled />
                    </div>
                </div>
                <div class="containers">
                    <div class="label">
                        <span class="codigo"> Preço Sugerido <span class="obrigatorio">*</span> </span>
                    </div>
                    <div class="input">
                        <input
                            type="number"
                            min="0"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            max="99999"
                            maxlength="7"
                            placeholder="00000"
                            v-model="precoSolicitado"
                            id="inputPrecoSolicitado"
                            @input="validaPrecoSolicitado"
                            required
                        />
                    </div>
                    <div v-if="precoIsInvalid.valida" class="mensagemErro">{{ precoIsInvalid.mensagem }}</div>
                </div>
                <div class="containers">
                    <div class="label">
                        <span class="codigo"> Status </span>
                    </div>
                    <div class="input">
                        <input type="text" autocorrect="off" v-model="status" disabled />
                    </div>
                </div>
                <div class="containers">
                    <div class="label">
                        <span class="codigo"> Data de Solicitação </span>
                    </div>
                    <div class="input">
                        <input type="date" autocorrect="off" v-model="dataSolicitacao" disabled />
                    </div>
                </div>
                <div class="containers">
                    <div class="label">
                        <span class="codigo"> Horário de Solicitação </span>
                    </div>
                    <div class="input">
                        <input type="text" autocorrect="off" v-model="horaSolicitacao" disabled />
                    </div>
                </div>
                <div class="containers">
                    <div class="label">
                        <span class="codigo"> Valido Até <span class="obrigatorio">*</span> </span>
                    </div>
                    <div class="input">
                        <input
                            type="date"
                            class="input-validade"
                            autocorrect="off"
                            autocomplete="off"
                            :min="dataAtual()"
                            :max="produto.data_retirada"
                            placeholder="dd/mm/aaaa"
                            ref="validoAte"
                            v-model="validoAte"
                            @input="validaDate(produto)"
                        />
                    </div>
                    <div v-if="dataIsInvalid.valida" class="mensagemErro">{{ dataIsInvalid.mensagem }}</div>
                </div>
                <div class="containers">
                    <div class="label">
                        <span class="codigo"> Estoque ERP </span>
                    </div>
                    <div class="input">
                        <input type="number" autocorrect="off" v-model="estoque" disabled />
                    </div>
                </div>
                <div class="containers">
                    <div class="label">
                        <span class="codigo"> Usuário </span>
                    </div>
                    <div class="input">
                        <input type="text" autocorrect="off" v-model="nome" disabled />
                    </div>
                </div>
            </div>

            <transition name="slide">
                <div class="container-images">
                    <img
                        v-for="imagem in produto.imagens"
                        :key="imagem"
                        :src="`https://api-homologacao.minus.eco.br/images/hmg/` + imagem"
                        alt=""
                    />
                </div>
            </transition>
        </form>

        <footer>
            <button class="format-footer cancel" @click="showJustify = true">
                <h3>Recusar</h3>
            </button>
            <button class="format-footer save" type="submit" @click="aceitaPedido">
                <h3>Aceitar</h3>
            </button>
        </footer>

        <transition name="fade">
            <div v-if="showJustify" class="popUp">
                <div class="tela-mensagem">
                    <div class="cancel-msg">
                        Justifique a recusa do pedido:
                        <div class="close-cancel" @click="showJustify = false">
                            <img src="../../assets/img/close-black.svg" alt="fechar a modal" />
                        </div>
                    </div>
                    <div class="container-text">
                        <textarea
                            class="text-justify"
                            minlength="1"
                            maxlength="100"
                            name="justify"
                            v-model="justify"
                            id="justify"
                            cols="50"
                            rows="7"
                            required
                        ></textarea>
                    </div>
                    <div v-if="justifyIsInvalid.valida" class="mensagemErro erro-justifique">
                        {{ justifyIsInvalid.mensagem }}
                    </div>
                    <div class="cancel-btn">
                        <button @click.prevent="(showJustify = false), (justify = '')" type="button">Descartar</button>
                        <button @click="recusaPedido" type="button">Enviar</button>
                    </div>
                </div>
            </div>
        </transition>

        <transition name="fade">
            <div class="tela-over" v-if="showProduto">
                <div class="tela-imagem">
                    <div class="close-imagem" @click="showProduto = false">
                        <img src="../../assets/img/close-black.svg" alt="fechar a imagem" />
                    </div>
                    <img
                        id="imgFull"
                        :src="
                            'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' +
                            produto.codigo_de_barras +
                            '.webp'
                        "
                        @error="replaceByDefault"
                        alt="produto 1"
                    />
                </div>
            </div>
        </transition>

        <msg-sucess v-if="showSucess" :mensagemSucess="msgS"></msg-sucess>
        <msg-error v-if="showAlert" @closeError="closeError" :mensagemError="msgE"></msg-error>
    </div>
</template>

<script>
import img from '../../assets/img/broken-image.svg';
import MsgSucess from '../../components/MsgSucess';
import MsgError from '../../components/MsgError';
import HTTP from '../../http/http.js';
import moment from 'moment';

export default {
    data() {
        return {
            produto: null,
            codigo: '',
            descricao: '',
            loja: '',
            quantidade: '',
            validade: '',
            precoAtual: '',
            precoSolicitado: '',
            status: '',
            dataSolicitacao: '',
            horaSolicitacao: '',
            showProduto: false,
            showSucess: false,
            showAlert: false,
            precoMaior: false,
            msgS: '',
            msgE: '',
            precoIsInvalid: {
                mensagem: '',
                valida: false,
            },
            dataIsInvalid: {
                mensagem: '',
                valida: false,
            },
            justifyIsInvalid: {
                mensagem: '',
                valida: false,
            },
            precoSolicitadoIsInvalid: {
                mensagem: '',
                valida: false,
            },
            estoque: '',
            validoAte: '',
            nome: '',
            showJustify: false,
            justify: '',
        };
    },
    components: {
        MsgSucess,
        MsgError,
    },
    computed: {
        getPedidoPreco() {
            return this.$store.getters.getPedidoPreco;
        },
        getPedidos() {
            return this.$store.getters.getPedidos;
        },
    },
    created() {
        let codigo = history.state.codigo;
        let validade = history.state.validade;
        this.$store.dispatch('setCodigoPrecos', codigo);
        this.$store.dispatch('setValidadePrecos', validade);
        this.produto = this.getPedidoPreco;

        this.descricao = this.produto.descricao;
        this.loja = this.produto.loja;
        this.precoAtual = this.produto.preco_atual;
        this.precoSolicitado = this.produto.preco_solicitado;
        this.status = this.produto.status;
        this.quantidade = this.produto.quantidade;
        this.validade = this.produto.data_validade;
        this.dataSolicitacao = this.produto.data_solicitacao;
        this.horaSolicitacao = this.formatHour(this.produto.hora_solicitacao);
        this.codigo = this.produto.codigo_de_barras;
        this.estoque = this.produto.estoque;
        this.nome = this.produto.nome;
    },
    methods: {
        formatHour(value) {
            if (value) {
                let hourSplit = value.split(':');
                return hourSplit[0] + ':' + hourSplit[1];
            } else {
                return 'Sem registro';
            }
        },

        validaPrecoSolicitado() {
            if (this.precoAtual != null) {
                this.precoSolicitadoIsInvalid = this.precoSolicitado <= 0 || this.precoSolicitado == '' ? true : false;
                this.precoMaior = this.precoSolicitado > this.precoAtual ? true : false;
            } else {
                this.precoSolicitadoIsInvalid = this.precoSolicitado <= 0 || this.precoSolicitado == '' ? true : false;
            }

            var input = document.querySelector('#inputPrecoSolicitado');
            input.addEventListener('keypress', function (e) {
                if (!checkChar(e)) {
                    e.preventDefault();
                }
            });
            function checkChar(e) {
                var char = String.fromCharCode(e.keyCode);

                var pattern = '[0-9,.]';
                if (char.match(pattern)) {
                    return true;
                }
            }

            if (this.precoSolicitadoIsInvalid) {
                this.precoIsInvalid = {
                    mensagem: 'Preencha todos os preços necessários.',
                    valida: true,
                };
                return true;
            } else if (this.precoMaior) {
                this.precoIsInvalid = {
                    mensagem: 'O preço sugerido não pode ser maior que o preço atual.',
                    valida: true,
                };
                return true;
            } else {
                this.precoIsInvalid = {
                    mensagem: '',
                    valida: false,
                };
                return false;
            }
        },

        validaJustify() {
            this.justifyIsInvalid = this.justify == '' ? true : false;

            if (this.justifyIsInvalid) {
                this.justifyIsInvalid = {
                    mensagem: 'Informação Obrigatória',
                    valida: true,
                };
                return true;
            } else {
                this.justifyIsInvalid = {
                    mensagem: '',
                    valida: false,
                };
                return false;
            }
        },

        validaDate(target) {
            if (this.validoAte == '') {
                this.dataIsInvalid = {
                    mensagem: 'Preencha todas as datas necessárias.',
                    valida: true,
                };
                return true;
            } else if (this.validoAte >= target.data_validade || this.validoAte < this.dataAtual()) {
                this.dataIsInvalid = {
                    mensagem: 'Data inválida.',
                    valida: true,
                };
                return true;
            } else {
                this.dataIsInvalid = {
                    mensagem: '',
                    valida: false,
                };
                return false;
            }
        },
        replaceByDefault(e) {
            e.target.src = img;
        },

        dataAtual() {
            let momentData = moment();
            let minDate = '';
            if (momentData.hour() < 13) {
                minDate = momentData.add(1, 'd');
            } else {
                minDate = momentData.add(2, 'd');
            }
            let dataHoje = minDate.format('YYYY-MM-DD');
            return dataHoje;
        },

        removePedido() {
            let codigo = this.codigo;
            let validade = this.validade;
            let loja = this.loja;

            const itemToBeRemoved = { codigo, validade, loja };

            let todosPedidos = this.getPedidos;

            this.$store.dispatch('deletePedido', itemToBeRemoved);

            todosPedidos.splice(
                todosPedidos.findIndex(
                    (a) => a.codigo_de_barras == itemToBeRemoved.codigo && a.data_validade == itemToBeRemoved.validade
                ),
                1
            );
        },
        aceitaPedido() {
            if (this.validaPrecoSolicitado()) return;
            if (this.validaDate(this.produto)) return;

            const data = { precos: [] };
            let valido_ate = moment(this.validoAte).format('YYYY-MM-DD');
            data.precos.push({
                status: 'Aprovado',
                validade: this.validade,
                codigo_de_barras: this.codigo,
                loja: this.loja,
                preco_solicitado: this.precoSolicitado,
                valido_ate: valido_ate,
                margem: null,
                preco_venda_atual: this.produto.preco_atual,
            });

            const dataVuex = { pedidos: [] };
            dataVuex.pedidos.push({
                status: 'Aprovado',
                data_validade: this.validade,
                quantidade: this.quantidade,
                codigo_de_barras: this.codigo,
                loja: this.loja,
                preco_atual: this.precoAtual,
                preco_solicitado: this.precoSolicitado,
                valido_ate: valido_ate,
                categoria_2: this.produto.categoria_2,
            });

            if (navigator.onLine) {
                let loja = sessionStorage.getItem('loja');

                HTTP.patch(`${loja}/admin/precos`, data)
                    .then(() => {
                        this.showSucess = true;
                        this.msgS = 'Pedido aprovado com sucesso!';
                        this.$store.dispatch('setNovoHistorico', dataVuex);
                        this.removePedido();

                        setTimeout(() => {
                            this.showSucess = false;
                            this.$router.go(-1);
                        }, 1500);
                    })
                    .catch(() => {
                        this.showAlert = true;
                        this.msgE = 'Erro ao aprovar o produto, tente novamente.';

                        setTimeout(() => {
                            this.showAlert = false;
                            this.$router.go(-1);
                        }, 1500);
                    });
            } else {
                let precosSalvos = JSON.parse(localStorage.getItem('precos'));

                let dataToLS = null;

                if (precosSalvos) {
                    precosSalvos.push(data);

                    dataToLS = JSON.stringify(precosSalvos);
                } else {
                    dataToLS = JSON.stringify([data]);
                }

                this.msgS = 'Seu pedido será atualizado ao sincronizar!';
                this.showSucess = true;

                localStorage.setItem('precosEdit', dataToLS);

                this.$store.dispatch('plusNumeroSync');

                setTimeout(() => {
                    this.showSucess = false;
                    this.$router.go(-1);
                }, 1500);
            }
        },

        recusaPedido() {
            if (this.validaJustify()) return;

            const data = { precos: [] };
            data.precos.push({
                status: 'Reprovado',
                validade: this.validade,
                codigo_de_barras: this.codigo,
                loja: this.loja,
                preco_solicitado: this.precoSolicitado,
                valido_ate: this.validoAte,
                justificativa: this.justify,
                margem: null,
                preco_venda_atual: this.precoAtual,
            });

            const dataVuex = { pedidos: [] };
            dataVuex.pedidos.push({
                status: 'Reprovado',
                data_validade: this.validade,
                quantidade: this.quantidade,
                codigo_de_barras: this.codigo,
                loja: this.loja,
                preco_atual: this.precoAtual,
                preco_solicitado: this.precoSolicitado,
                valido_ate: this.validoAte,
                categoria_2: this.produto.categoria_2,
            });

            if (navigator.onLine) {
                let loja = sessionStorage.getItem('loja');

                HTTP.patch(`${loja}/admin/precos`, data)
                    .then(() => {
                        this.showSucess = true;
                        this.msgS = 'Pedido recusado com sucesso!';
                        this.$store.dispatch('setNovoHistorico', dataVuex);

                        this.removePedido();

                        setTimeout(() => {
                            this.showSucess = false;
                            this.$router.go(-1);
                        }, 1500);
                    })
                    .catch(() => {
                        this.showAlert = true;
                        this.msgE = 'Erro ao recusar o produto, tente novamente.';

                        setTimeout(() => {
                            this.showAlert = false;
                            this.$router.go(-1);
                        }, 1500);
                    });
            } else {
                let precosSalvos = JSON.parse(localStorage.getItem('precos'));

                let dataToLS = null;

                if (precosSalvos) {
                    precosSalvos.push(data);

                    dataToLS = JSON.stringify(precosSalvos);
                } else {
                    dataToLS = JSON.stringify([data]);
                }

                this.msgS = 'Seu pedido será atualizado ao sincronizar!';
                this.showSucess = true;

                localStorage.setItem('precosEdit', dataToLS);

                this.$store.dispatch('plusNumeroSync');

                setTimeout(() => {
                    this.showSucess = false;
                    this.$router.go(-1);
                }, 1500);
            }
        },
    },
};
</script>

<style scoped>
.container-form {
    padding: 15px;
    margin-top: 56px;
    margin-bottom: 66px;
    overflow-x: hidden;
}

.container-images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    padding: 20px;
    border-radius: 10px;
    place-items: center;
    background-color: rgb(235, 235, 235);
    margin-top: 40px;
}

.container-images img {
    min-width: 300px;
    max-width: 300px;
    min-height: 400px;
    max-height: 400px;
    border-radius: 10px;
}

.container-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

/* .containers {
    padding-right: 15px;
} */

.produto-img {
    display: flex;
    margin-top: 9px;
    margin-bottom: 15px;
    border: 1px solid #bdc1c6;
    padding: 5px;
    place-content: center;
}

.input {
    margin-top: 5px;
    margin-bottom: 10px;
    align-items: center;
}

input {
    border: 1px solid var(--cinza);
    border-radius: 4px;
    font-size: 1.1rem !important;
    padding: 12px;
    width: 100%;
    text-overflow: ellipsis;
    outline-color: var(--verde);
    max-height: 49px;
}

input:disabled {
    background-color: #ebebeb;
}

.input-validade {
    background-color: #fff;
}

input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.label span {
    font-size: 1.2rem;
}

.obrigatorio {
    color: var(--vermelho);
}

.label {
    display: flex;
    align-items: center;
}

.mensagemErro {
    color: var(--vermelho);
    font-size: 1rem;
    margin-top: 5px;
}

.erro-justifique {
    margin-left: 5%;
}

.open-img {
    position: absolute;
    display: flex;
    right: 21px;
    cursor: pointer;
    box-shadow: 0 0 0 2px #cdcdcd;
    background-color: #ececec;
    transition: all 0.2s;
    border-radius: 50%;
    padding: 10px;
}

.open-img:hover {
    border-radius: 5px;
    transition: all 0.2s;
}

.open-img img {
    width: 36px;
}

#produtoImg {
    max-width: 60%;
    max-height: 35vh;
    min-height: 80px;
    margin: auto;
}

.tela-over {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-imagem {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 100px 25px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-imagem {
    position: absolute;
    top: -21px;
    right: -21px;
    box-shadow: 0 0 0 2px #cdcdcd;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #ececec;
    transition: all 0.2s;
}

.close-imagem:hover {
    border-radius: 5px;
    transition: all 0.2s;
}

#imgFull {
    max-width: 500px;
    max-height: 500px;
    border: 1px solid #bdc1c6;
    padding: 20px;
}

footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: grid;
    z-index: 2;
    grid-template-columns: 1fr 1fr;
    background-color: var(--vermelho);
    box-shadow: 0 0 10px #cdcdcd;
}

.format-footer {
    place-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 19px;
    border: none;
    cursor: pointer;
}

.format-footer:first-child {
    background-color: #fff;
}

.format-footer h3 {
    font-size: 18px;
    margin-top: 5px;
    color: var(--branco);
    font-weight: 500;
}

.cancel h3 {
    color: #000;
}

.cancel:hover {
    background-color: var(--azul-escuro);
}

.cancel:hover,
.cancel:hover h3 {
    color: #fff;
}

.save {
    background-color: var(--verde);
    border: none;
}

.save:hover {
    background-color: var(--azul-escuro);
}

.save h3 {
    font-weight: 600;
}

.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 60%);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 25%;
    top: 10%;
    right: 25%;
    margin: 18px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 10px;
}

.tela-mensagem h3 {
    padding: 5px 15px;
    font-size: 17px;
}

.tela-mensagem img {
    width: 200px;
}

.cancel-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 23px;
    font-weight: bold;
}

.close-cancel {
    display: flex;
    background-color: #d3d3d3;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.close-cancel img {
    width: 24px;
}

.close-cancel:hover {
    border-radius: 50%;
    transition: all 0.2s;
    background-color: #f4f4f4;
    border: 1px solid #d3d3d3;
}

.container-text {
    margin-left: 20%;
    margin-right: 20%;
    display: flex;
    justify-content: center;
}

.text-justify {
    border-radius: 5px;
    outline-color: var(--verde);
}

.cancel-btn {
    margin-top: 15px;
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.cancel-btn button {
    padding: 7px 15px;
    font-weight: bold;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn button:hover {
    position: relative;
    top: 2px;
    background-color: var(--verde);
    color: #fff;
}
</style>
